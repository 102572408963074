import React, {useState, useEffect} from 'react'
import { Header, 
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    ModalTitle,
    Button,
    SelectOption,
    ProgressBar,
    Dialog,
    Notification} from "@hai/ui-react";

import { Col, Row } from "reactstrap";

import axios from "../../../infra/axios";
import Select from "react-select";

const ProductVersionMetadata = (props) => {

    const [loading, setLoading] = useState(false);
    const [metaDataDto,SetMetaDataDto] = useState({
        productVersionId : props.metaData==null?null:props.metaData.productVersionId,
        blobUri : props.metaData==null?'':props.metaData.blobUri,
        imageLink : props.metaData==null?'':props.metaData.imageLink,
        imageType:props.metaData==null?'':props.metaData.imageType,
        md5 : props.metaData==null?'':props.metaData.md5,
        sha1 : props.metaData==null?'':props.metaData.sha1,
        sha2 : props.metaData==null?'':props.metaData.sha2,
        id : props.metaData==null?null:props.metaData.id,
        salesforceFileName :props.metaData==null?'':props.metaData.salesforceFileName,
        salesforceDownloadUrl:props.metaData==null?'':props.metaData.salesforceDownloadUrl
    });
    const [producVersionId, SetProducVersionId] = useState(props.productVersionId);
    const [showWarningdlg,SetShowWarningdlg] = useState(false);
    const [warningTitle, SetWarningTitle] = useState('Warning');
    const [warningContent, SetWarningContent] = useState(['This operation only update salesforce file name in Metadata, if you want to generate a new',
                                                          'file in salesforce download center, you need to click Create Salesforce Download link',
                                                          'but it will not remove the file with old salesforce file name.',
                                                          'Are you sure you want to update it?']);

    const [resultMessage,SetResultMessage] = useState('');

    useEffect( () => {
        SetMetaDataDto({
            productVersionId : props.metaData==null?null:props.metaData.productVersionId,
            blobUri : props.metaData==null?'':props.metaData.blobUri,
            imageLink : props.metaData==null?'':props.metaData.imageLink,
            imageType:props.metaData==null?'':props.metaData.imageType,
            md5 : props.metaData==null?'':props.metaData.md5,
            sha1 : props.metaData==null?'':props.metaData.sha1,
            sha2 : props.metaData==null?'':props.metaData.sha2, 
            id : props.metaData==null?null:props.metaData.id, 
            salesforceFileName :props.metaData==null?'':props.metaData.salesforceFileName,
            salesforceDownloadUrl:props.metaData==null?'':props.metaData.salesforceDownloadUrl
                
        });
     
    }, [props.metaData])

    useEffect( () => {
        SetProducVersionId(props.productVersionId);
    }, [props.productVersionId]);

    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)
    const titleStr = props.metaData==null?'Add Version Image':'Update Version Image'
    const buttonTitleStr = props.metaData==null?'Create':'Update'

    const onCancelDlg = () =>{
      SetShowWarningdlg(false);
    }
  
    const onConfirmDlg = () =>{
      handleUpdate();  
    }

    const handleHideModal = (isCancelled) =>{

      SetMetaDataDto({
        productVersionId : props.metaData==null?null:props.metaData.productVersionId,
        blobUri : props.metaData==null?'':props.metaData.blobUri,
        imageLink : props.metaData==null?'':props.metaData.imageLink,
        imageType:props.metaData==null?'':props.metaData.imageType,
        md5 : props.metaData==null?'':props.metaData.md5,
        sha1 : props.metaData==null?'':props.metaData.sha1,
        sha2 : props.metaData==null?'':props.metaData.sha2, 
        id : props.metaData==null?null:props.metaData.id, 
        salesforceFileName :props.metaData==null?'':props.metaData.salesforceFileName,
        salesforceDownloadUrl:props.metaData==null?'':props.metaData.salesforceDownloadUrl
            
      });

        setLoading(false);
        props.onHide();
        if(!isCancelled){
          props.getVersion();
        }
        
      }

      const handleUpdate = () => {      
        axios
        .put(
          process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductVersions/metadata/" + metaDataDto.id,
          metaDataDto
        )
        .then((reponse) => {
          SetResultMessage('Update Metadata successful!');
          setShow(true);
          handleHideModal(false);
        }); 
      }

      const handleCreate = () =>{
        if(metaDataDto.imageType === '' || metaDataDto.imageType === undefined || metaDataDto.imageType === null){
            alert('Please select a Image type!');
            return;   
          }

          if(props.metaData === null){
            axios
            .post(
              process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductVersions/" + producVersionId + '/metadata',
              metaDataDto
            )
            .then((reponse) => {
              SetResultMessage('Create Metadata successful!');
              setShow(true)
              handleHideModal(false);
            }); 
          }
          else{
            if (metaDataDto.salesforceFileName !== props.metaData.salesforceFileName){
              SetShowWarningdlg(true);
            }else
            {
              handleUpdate();
            }                 
          }
              
      }


    var ImageTypes = ["FRIMWARE", "WIN-MSI", "OVA", "ISO", "MAC-PKG"];
    return (
        <div>
        { loading && <ProgressBar
            leftLabel="Loading: "
            barColors={{
                background: '#599bff',
                right: '#599bff',
                left: '#2fccd3',
            }}
            hideRight={true}
            animate={1}
        />}
        {!loading && 
          <div>
            <Modal show={props.show} size="lg">
            <ModalHeader>
              <ModalTitle>{titleStr}</ModalTitle>
            </ModalHeader>
            <ModalBody>
 
            <Row>
              <Col>
                <FormLabel>Image Type</FormLabel>
                <FormControl
                  as="select"
                  defaultValue={metaDataDto?.imageType}
                  onChange={(e) => {
                    SetMetaDataDto(prevState => ({
                      ...prevState,   imageType: e.target.value
                    }))
                }}
                >                   
                {ImageTypes.map((p) => {
                  return <SelectOption value={p} key={p}>{p}</SelectOption>;
                })}
                </FormControl>
              </Col>
              {/*<Col>
                <FormLabel>Image Link</FormLabel>
                <FormControl
                  disabled = {true}
                  value = {metaDataDto?.imageLink}
                  onChange={(e) => {
                    SetMetaDataDto(prevState => ({
                      ...prevState,   imageLink: e.target.value
                    }))
                }}
                />
              </Col>*/}
             <Col>
            <FormGroup>
            <FormLabel>Md5</FormLabel>
            <FormControl
              value={metaDataDto?.md5}
              onChange={(e) => {
                SetMetaDataDto(prevState => ({
                  ...prevState,   md5: e.target.value
                }))
            }}
            />
            </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
            <FormLabel>Sha1</FormLabel>
            <FormControl
              value={metaDataDto?.sha1}
              onChange={(e) => {
                SetMetaDataDto(prevState => ({
                  ...prevState,   sha1: e.target.value
                }))
            }}
            />
            </Col>
            <Col>
            <FormGroup>
              <FormLabel>Sha2</FormLabel>
              <FormControl
                value={metaDataDto?.sha2}
                onChange={(e) => {
                    SetMetaDataDto(prevState => ({
                      ...prevState,   sha2: e.target.value
                    }))
                }}
              />
              </FormGroup>
              </Col>
          </Row>
          {/*}
          <Row>
            <Col>
            <FormGroup>
              <FormLabel>Sha2</FormLabel>
              <FormControl
                value={metaDataDto?.sha2}
                onChange={(e) => {
                    SetMetaDataDto(prevState => ({
                      ...prevState,   sha2: e.target.value
                    }))
                }}
              />
              </FormGroup>
              </Col>
              <Col>
            <FormGroup>
              <FormLabel>Blob URI</FormLabel>
              <FormControl
                disabled = {true}
                value={metaDataDto?.blobUri}
                onChange={(e) => {
                    SetMetaDataDto(prevState => ({
                      ...prevState,   blobUri: e.target.value
                    }))
                }}
              />
              </FormGroup>
              </Col>
          </Row>
          <Row>
            <Col>
            <FormGroup>
              <FormLabel>Salesforce File Name</FormLabel>
              <FormControl
                disabled = {true}
                value={metaDataDto?.salesforceFileName}
                onChange={(e) => {
                    SetMetaDataDto(prevState => ({
                      ...prevState,   salesforceFileName: e.target.value
                    }))
                }}
              />
              </FormGroup>
              </Col>
          </Row>*/}
            <div/>       
              </ModalBody>
              <ModalFooter>
              <Button variant="primary" onClick={() => handleCreate()}>
                {buttonTitleStr}
              </Button>
              <Button onClick={() => handleHideModal(true)}>
                Cancel
              </Button>
            </ModalFooter>
              </Modal>
          </div>} 
            <Dialog
            dynamicResizing={{ minWidth: 100, maxWidth: 500, margin: 100 }}
            headline={warningTitle}
            bodyText={warningContent}
            onClose={()=>SetShowWarningdlg(false)}
            show={showWarningdlg}
            buttons={[
              { label: 'CANCEL',onClick: () => onCancelDlg() },
              { label: 'YES', variant: 'danger' ,onClick: () => onConfirmDlg()},
            ]}
            />
          <Notification
            delay={3000}
            position="upper-right"
            show={show}
            description="Success"
            onDismiss={handleClose}
            message={resultMessage}
            variant="Success"
          />
          </div>

    );
}

export default ProductVersionMetadata;