import React, { Component } from "react";
import axios from "../../../infra/axios";
import {
  Header,
  List,
  ListHeader,
  TableRow,
  Animation,
  SelectOption,
  FormLabel,
  FormControl,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalFooter,
  FormGroup,
 ProgressBar } from "@hai/ui-react";
 import moment from "moment";
import { Row, Col, ModalBody } from "reactstrap";

class HaivisionProductTypeVersionAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProductVersionAdd: false,
      isUpdateVersion : this.props.isUpdateVersion,
      selectedVersion: this.props.isUpdateVersion === true?this.props.selectedVersion : null,
      productTypeId: this.props.productTypeId,
      newProductVersion: {
        majorVersion: this.props.isUpdateVersion === true?this.props.selectedVersion?.majorVersion : "",
        minorVersion: this.props.isUpdateVersion === true?this.props.selectedVersion?.minorVersion :"",
        patchVersion: this.props.isUpdateVersion === true?this.props.selectedVersion?.patchVersion :"",
        publishedDate:this.props.isUpdateVersion === true?this.props.selectedVersion?.publishedDate :"",
        imageLink:this.props.isUpdateVersion === true?this.props.selectedVersion?.imageLink :"",
        imageType: this.props.isUpdateVersion === true ? this.props.selectedVersion?.imageType :"HAI",
        md5:this.props.isUpdateVersion === true?this.props.selectedVersion?.md5 :"",
        sha1:this.props.isUpdateVersion === true?this.props.selectedVersion?.sha1 :"",
        sha2:this.props.isUpdateVersion === true?this.props.selectedVersion?.sha2 :"",
        versionDescription:this.props.isUpdateVersion === true?this.props.selectedVersion?.versionDescription : "",
        productTypeId: this.props.productTypeId,
      },
    };   
  }

  componentWillReceiveProps(nextProps) {
    if(this.state.isUpdateVersion !== nextProps.isUpdateVersion || this.state.selectedVersion !== nextProps.selectedVersion || this.state.showProductVersionAdd !== nextProps.show){

      var publishDateStr = '';
      if(nextProps.isUpdateVersion === true && nextProps.selectedVersion?.publishedDate !== null && nextProps.selectedVersion?.publishedDate !== ''){
        publishDateStr = moment(nextProps.selectedVersion.publishedDate).utc().format("YYYY-MM-DD");
      }
      this.setState((prev) => {
        prev.showProductVersionAdd = nextProps.show;
        prev.productTypeId = nextProps.productTypeId;
        prev.newProductVersion.productTypeId = nextProps.productTypeId;
        prev.newProductVersion.majorVersion =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.majorVersion : "";
        prev.newProductVersion.minorVersion =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.minorVersion : "";
        prev.newProductVersion.patchVersion =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.patchVersion : "";
        prev.newProductVersion.publishedDate =  publishDateStr;
        prev.newProductVersion.imageLink =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.imageLink : "";
        prev.newProductVersion.imageType =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.imageType : "Firmware Update";
        prev.newProductVersion.md5 =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.md5 : "";
        prev.newProductVersion.sha1 =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.sha1 : "";
        prev.newProductVersion.sha2 =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.sha2 : "";
        prev.newProductVersion.versionDescription =  nextProps.isUpdateVersion === true?nextProps.selectedVersion?.versionDescription : "";
        prev.isUpdateVersion =  nextProps.isUpdateVersion;
        prev.selectedVersion =  nextProps.selectedVersion;
        return prev;
      });
    }
  }

  submitProductVersion (){
    if(this.state.isUpdateVersion){
      this.submitProductVersionUpdate();
    }
    else{
      this.submitProductVersionAdd();
    }
  }

  submitProductVersionAdd() {

    if(this.state.newProductVersion.publishedDate !== '' && this.state.newProductVersion.publishedDate !== undefined && !this.isValidDate(this.state.newProductVersion.publishedDate)){
      alert('The format of Published Date should be yyyy-mm-dd!');
      return;
    } 

    axios
      .post(
        process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductVersions",
        this.state.newProductVersion
      )
      .then((response) => {
        this.props.handleProductVersionAdd();
        this.props.getVersion();
        this.setState((prev) => {
          prev.newProductVersion = {
            majorVersion: "",
            minorVersion: "",
            patchVersion: "",
            publishedDate:"",
            versionDescription: "",
            productTypeId: "",
          };
          return prev;
        });
      });
  }

   isValidDate(dateString) {
    const regEx = new RegExp(/^\d{4}-\d{2}-\d{2}$/);
    return regEx.test(dateString);
  }

  submitProductVersionUpdate() {
    if(this.state.newProductVersion.publishedDate !== '' && this.state.newProductVersion.publishedDate !== undefined && !this.isValidDate(this.state.newProductVersion.publishedDate)){
      alert('The format of Published Date should be yyyy-mm-dd!');
      return;
    } 

    axios
      .put(
        process.env.REACT_APP_LIC_API_BASE_URL + "/Products/ProductVersions/" + this.state.selectedVersion.id,
        this.state.newProductVersion
      )
      .then((response) => {
        this.props.handleProductVersionAdd();
        this.props.getVersion();
        this.setState((prev) => {
          prev.newProductVersion = {
            majorVersion: "",
            minorVersion: "",
            patchVersion: "",
            publishedDate:"",
            versionDescription: "",
            productTypeId: "",
          };
          return prev;
        });
      });
  }

  render() {
    return (
      <Modal show={this.state.showProductVersionAdd} size="xl">
        <ModalHeader>
          <ModalTitle>Add Product Version</ModalTitle>
        </ModalHeader>
        <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <FormLabel>Major Version</FormLabel>
              <FormControl
                disabled = {this.state.isUpdateVersion}          
                value = {this.state.newProductVersion.majorVersion}
                onChange={(e) => {
                  var text = e.target.value;
                  this.setState((prev) => {
                    prev.newProductVersion.majorVersion = text;
                    return prev;
                  });
                }}
              />
              </FormGroup>
            </Col>
            </Row>
            <Row>
            <Col>
            <FormGroup>
            <FormLabel>Minor Version</FormLabel>
            <FormControl
              disabled = {this.state.isUpdateVersion}  
             value = {this.state.newProductVersion.minorVersion}
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProductVersion.minorVersion = text;
                  return prev;
                });
              }}
            />
            </FormGroup>
            </Col>
            </Row>
            <Row>
            <Col>
            <FormGroup>
            <FormLabel>Patch Version</FormLabel>
            <FormControl
              disabled = {this.state.isUpdateVersion}  
              value = {this.state.newProductVersion.patchVersion}
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProductVersion.patchVersion = text;
                  return prev;
                });
              }}
            />
            </FormGroup>         
            </Col>
            </Row>
            <Row>
            <Col>
            <FormGroup>
            <FormLabel>Published Date (Format : yyyy-mm-dd)</FormLabel>
            <FormControl
              value = {this.state.newProductVersion.publishedDate}
              onChange={(e) => {
                var text = e.target.value;
                this.setState((prev) => {
                  prev.newProductVersion.publishedDate = text;
                  return prev;
                });
              }}
            />
            </FormGroup>
            </Col> 
            </Row> 
            <Row>         
            <Col>
              <FormGroup>
              <FormLabel>Version Description</FormLabel>
              <FormControl
                value={this.state.newProductVersion.versionDescription}
                onChange={(e) => {
                  var text = e.target.value;
                  this.setState((prev) => {
                    prev.newProductVersion.versionDescription = text;
                    return prev;
                  });
                }}
              />
              </FormGroup>
            </Col> 
            </Row>        
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            onClick={() => this.submitProductVersion()}
          >
            Submit
          </Button>
          <Button onClick={() => this.props.handleProductVersionAdd()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default HaivisionProductTypeVersionAdd;
